import Link from "next/link";
import { trackClick } from "scripts/segment";
import { pageName } from "utils/events_utils";

function WhatSection() {
  return (
    <section className="py-[40px] px-[16px] md:py-[60px] bg-[#E3E2FF3D] md:bg-[#fafafa]">
      <h2 className="font-bold text-[24px] md:text-[36px] leading-[28px] md:leading-[46px] text-[#1B1D6A] md:text-center">
        What is refinancing?
      </h2>
      <p className="text-[#646464] text-[14px] leading-[21px] md:leading-[32px] md:text-[20px] md:w-8/12 m-auto my-[24px] md:text-center">
        Refinancing a loan means paying back your current loan with another loan
        taken at a lower interest rate. Refinancing helps you lower your EMI
        payments, so you can save more money!
      </p>
      <div className="hidden md:flex justify-center py-6">
        <Link href="#my-eligibility-id" scroll={false}>
          <button
            type="button"
            className="font-bold rounded text-[15px] leading-[20px] py-3 px-[68px] bg-primary text-[#fff]"
            onClick={() => {
              trackClick(pageName.REFI_LANDING_PAGE, {
                widgetName: "What is Refinancing",
                widgetFormat: "Banner",
                contentName: "Check Eligibility",
                contentFormat: "Button",
              });
            }}
          >
            Check Eligibility
          </button>
        </Link>
      </div>
    </section>
  );
}

export default WhatSection;
